<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Icondatenbank Veranstaltungen">
      <div class="icons-grid">
        <div v-for="icon in icons" :key="icon" class="icon-wrapper">
          <div class="icon-background">
            <img :src="require('../assets/images/event-icons/' + icon)" />
          </div>
          <a
            download
            target="_blank"
            :href="require('../assets/images/event-icons/' + icon)"
            >Download</a
          >
        </div>
      </div>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"

export default {
  metaInfo: {
    title: "Icons"
  },
  components: {
    TheBreadcrumbs,
    PageContent
  },
  data() {
    return {
      icons: [
        "schwangerschaft.svg",
        "babys.svg",
        "kindergarten.svg",
        "kita.svg",
        "lebenslagen.svg",
        "partnerschaft.svg",
        "family.svg",
        "teenies.svg",
        "dad_01.svg",
        "dad_02.svg",
        "bazaar.svg",
        "bike.svg",
        "luggage_2.svg",
        "mic.svg",
        "online.svg",
        "tipi.svg",
        "diet.svg",
        "doctor.svg",
        "home_2.svg",
        "home.svg",
        "juice.svg",
        "lotus.svg",
        "rope.svg",
        "scale.svg",
        "stretch.svg",
        "tea.svg",
        "yoga.svg",
        "bag.svg",
        "bowling.svg",
        "bulb.svg",
        "bus.svg",
        "crossroad.svg",
        "deaf.svg",
        "ecology.svg",
        "exhibition.svg",
        "forest.svg",
        "hand.svg",
        "map.svg",
        "meeting.svg",
        "ruler.svg",
        "signing.svg",
        "soccer.svg",
        "stroller.svg",
        "teddy.svg",
        "wheelchair.svg"
      ]
    }
  },
  async created() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.icons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 24px;
}

.icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .icon-background {
    min-height: 100px;
    background-color: #68d391;
    margin-bottom: 8px;
    padding: 16px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  a {
    text-align: center;
  }
}
</style>
